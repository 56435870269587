import { render, staticRenderFns } from "./ExamReady.vue?vue&type=template&id=5415bc8e&scoped=true&"
import script from "./ExamReady.vue?vue&type=script&lang=js&"
export * from "./ExamReady.vue?vue&type=script&lang=js&"
import style0 from "./ExamReady.vue?vue&type=style&index=0&id=5415bc8e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5415bc8e",
  null
  
)

export default component.exports